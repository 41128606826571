<template>
  <v-container>
    <v-row>
      <div v-if="cardata.car" class="text-h4 mt-5 mb-5 pl-3">{{ pageTitle }} </div>
      <v-spacer></v-spacer>
      <v-btn class="mt-6" icon @click="goBack"><v-icon>mdi-arrow-left</v-icon></v-btn>
    </v-row>
    <v-divider class="mb-10"></v-divider>
    <v-row v-if="isLot">
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title>Veilingdetails</v-card-title>
          <v-card-text>
            <v-row>
<v-col cols="12" md="3" v-for="(rows, name) in keyData" :key="name">
  <v-simple-table dense>
    <template v-slot:default>
      <tbody>
        <tr
          v-for="(value, key) in rows" :key="key">
          <td>{{ value[0] }}</td>
          <td v-if="value[1]=='text'">{{ cardata.car[key] }}</td>
          <td v-if="value[1]=='link'"><a @click.stop @click="openLink(cardata.car[key])">link</a></td>
          <td v-if="value[1]=='amount'"><div v-if="cardata.car[key]">{{ parseInt(cardata.car[key]).toLocaleString('nl', {style: 'currency',currency: 'EUR',}) }}</div></td>
          <td v-if="value[1]=='percent'">{{ (cardata.car[key]) }}</td>
          <td v-if="value[1]=='arrow'">
            <v-icon color="green" v-if="cardata.car[key]==1"
              >mdi-arrow-up-bold</v-icon
            >
            <v-icon color="red" v-if="cardata.car[key]==-1"
              >mdi-arrow-down-bold</v-icon
            >
            <v-icon v-if="cardata.car[key]==0"
              >mdi-arrow-right-bold</v-icon
            >
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</v-col>

</v-row>
        </v-card-text>
    </v-card>
    </v-col>
    </v-row>
  <v-row>
    <v-col cols="12" md="12">
      <v-card>
        <v-card-title>Eerdere verkopen
          <v-spacer></v-spacer>
          <v-checkbox
          v-model="histYeargearOnly"
          label="Toon ook andere jaren en transmissies"
          ></v-checkbox>

        </v-card-title>
        <v-data-table
        dense
        :headers="histHeaders"
        :items="historyFiltered"
        item-key="id"
        >
        <template v-slot:item.purchase_price="{ item }">
          {{ item.purchase_price.toLocaleString('nl', {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}
        </template>
        <template v-slot:item.price="{ item }">
          {{ item.price.toLocaleString('nl', {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}
        </template>
        <template v-slot:item.vvi_url="{ item }">
          <v-btn icon @click="openLink('http://intranetv2.vaartland.nl/vvi3/'+item.licenseplate)">
            <v-icon>
              mdi-link
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</v-row>
<v-row>
  <v-col cols="12" md="12">
    <v-card>
      <v-card-title>Huidige voorraad
        <v-spacer></v-spacer>
        <v-checkbox
        v-model="invYeargearOnly"
        label="Toon ook andere jaren en transmissies"
        ></v-checkbox>
      </v-card-title>
      <v-data-table
      dense
      :headers="invHeaders"
      :items="inventoryFiltered"
      item-key="id"
      >
      <template v-slot:item.purchasePrice="{ item }">
        {{ item.purchasePrice.toLocaleString('nl', {minimumFractionDigits: 0, maximumFractionDigits: 0}) }}
      </template>
      <template v-slot:item.vvi_url="{ item }">
          <v-btn icon @click="openLink('http://intranetv2.vaartland.nl/vvi3/'+item.licenseplate)">
            <v-icon>
              mdi-link
            </v-icon>
          </v-btn>
      </template>
    </v-data-table>
  </v-card>
</v-col>
</v-row>

<v-row>
  <v-col cols="12" md="12">
    <v-card>
      <v-card-title v-if="isLot">Vergelijkbaar aanbod</v-card-title>
      <v-card-title v-if="!isLot">Veilingen</v-card-title>
      <v-data-table
      dense
      :headers="auctionHeaders"
      :items="auctions"
      item-key="id"
      >
      <template v-slot:item.bod="{ item }">
        {{ item.bod.toFixed(2) }}
      </template>
      <template v-slot:item.window_url="{ item }">
        <v-btn icon @click="openLink(item.window_url)">
          <v-icon>
            mdi-link
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.url="{ item }">
        <v-btn icon @click="openLink(item.url)">
          <v-icon>
            mdi-link
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</v-col>
</v-row>

<v-row>
  <v-col cols="12" md="12">
    <v-card v-if="cardata.car" height="700">
      <v-card-title>De score bedraagt {{ parseFloat(cardata.car.score).toFixed(2) }} op basis van de volgende indicatoren:</v-card-title>
      <GChart
      style="height: 80%;"
      type="BarChart"
      :data="scoreData"
      :options="scoreOptions"
      />
    </v-card>
  </v-col>
</v-row>

<v-row>
  <v-col cols="12" md="6">
    <v-card v-if="cardata.trends" height="500">
      <v-card-title>Google trends</v-card-title>
      <GChart
      v-if="cardata.trends[0].chartdata.length>0"
      style="height: 80%;"
      type="LineChart"
      :data="cardata.trends[0].chartdata"
      :options="chartOptions"
      />
      <div v-if="cardata.trends[0].chartdata.length==0" style="text-align: center;margin-top:25%;"

      > Geen gegevens</div>

    </v-card>
  </v-col>
  <v-col cols="12" md="6">
    <v-card v-if="cardata.views" height="500">
      <v-card-title>Views en clicks</v-card-title>
      <GChart
      style="height: 80%;"
      v-if="cardata.views[0].chartdata.length>0"
      type="LineChart"
      :data="viewsChartData"
      :options="viewsChartOptions"
      />
      <div v-if="cardata.views[0].chartdata.length==0" style="text-align: center;margin-top:25%;"
      > Geen gegevens</div>
    </v-card>
  </v-col>
</v-row>
</v-container>

</template>

<script>
  import { GChart } from 'vue-google-charts'

  export default {
    name: 'details',
    components: {
      GChart
    },

    data: () => ({
      cardata: {},
      histYeargearOnly: true,
      invYeargearOnly: true,
      auctionHeaders: [{
        text: 'Merk',
        align: 'start',
        filterable: true,
        value: 'make',
      },
      { text: 'Model', value: 'model' },
      { text: 'Brandstof', value: 'fuel' },
      { text: 'Carrosserie', value: 'body' },
      { text: 'Transmissie', value: 'gear' },
      { text: 'Jaar', value: 'build' },
      { text: 'Kilometerstand', value: 'kmstand' },
      { text: 'Specials', value: 'specials_info' },
      { text: 'Deadline', value: 'deadline' },
      { text: 'Aanbieder', value: 'auction_name' },

        { text: 'Window', value: 'window_url' },
        { text: 'Veiling', value: 'url' }],

        histHeaders: [{
          text: 'Merk',
          align: 'start',
          filterable: true,
          value: 'make',
        },
        { text: 'Model', value: 'model' },
        { text: 'Kilometers', value: 'kilometers'},
        { text: 'Brandstof', value: 'fuel' },
        { text: 'Bouwjaar', value: 'year' },
        { text: 'Transmissie', value: 'transmission' },
        { text: 'Kenteken', value: 'licenseplate' },
        { text: 'Omschrijving', value: 'type' },
        { text: 'Aankoopprijs', value: 'purchase_price' },
        { text: 'Verkoopprijs', value: 'price' },
        { text: 'Statijd', value: 'stt' },
        { text: 'Laatst verkocht', value: 'sale_date' },
        { text: 'Inkoper', value: 'purchaser_name'},
        { text: 'Vvi3', value: 'vvi_url'}],

        invHeaders: [{
          text: 'Merk',
          align: 'start',
          filterable: true,
          value: 'make',
        },
        { text: 'Model', value: 'model' },
        { text: 'Kilometers', value: 'kilometers'},
        { text: 'Brandstof', value: 'fuel' },
        { text: 'Bouwjaar', value: 'year' },
        { text: 'Transmissie', value: 'transmission' },
        { text: 'Kenteken', value: 'licenseplate' },
        { text: 'Omschrijving', value: 'type' },
        { text: 'Aankoopprijs', value: 'purchasePrice' },
        { text: 'Inkoper', value: 'purchaser_name'},
        { text: 'Laatste gegevens', value: 'lastUpdate' },
        { text: 'Online', value: 'online' },
        { text: 'Vvi3', value: 'vvi_url'}
        ],

        scoreColumns: [{name: "etr_scaled", trend: false, friendly: "ETR"},
        {name: "sold_m_scaled", trend: false, friendly: "Sales (markt)"},
        {name: "stt_m_sold_scaled", trend: false, friendly: "Statijd (markt, verkocht)"},
        {name: "stt_m_stock_scaled", trend: false, friendly: "Statijd (markt, voorraad)"},
        {name: "stt_i_scaled", trend: false, friendly: "Statijd (intern)"},
        {name: "itr_scaled", trend: false, friendly: "ITR"},
        {name: "sold_int_90_scaled", trend: false, friendly: "Sales (intern)"},
        {name: "sold_int_365_scaled", trend: false, friendly: "Sales (intern, lange termijn)"},
        {name: "stt_i_scaled", trend: false, friendly: "Statijd (intern)"},
        {name: "margin_relative_scaled", trend: false, friendly: "Marge"},
        {name: "last_sale_scaled", trend: false, friendly: "Recentheid"},
        {name: "etr_dyn_scaled", trend: true , friendly: "ETR-trend"},
        {name: "itr_dyn_scaled", trend: true, friendly: "ITR-trend"},
        {name: "gtrend_scaled", trend: true, friendly: "Searches"},
        {name: "trend_views_scaled", trend: true, friendly: "Views"},
        {name: "trend_clicks_scaled", trend: true, friendly:"Clicks"}],


        keyData: {'Voertuig': {make: ['merk', 'text'],
        model: ['model', 'text'],
        fuel: ['brandstof', 'text'],
        body: ['carrosserie', 'text'],
        build: ['bouwjaar', 'text'],
        gear: ['transmissie', 'text'],
        specials_info: ['specials', 'text'],
        hp: ['PK', 'text'],
        license_plate: ['kenteken', 'text'],
        kmstand: ['kilometers', 'text'],
        schade: ['schade', 'text'],
        user_search_matches: ['Zoekopdrachten', 'text']},

        'Courantheid': {
        auction_etr: ['ETR', 'text'],
        auction_itr: ['ITR', 'text'],
        stock_int: ['Eigen voorraad', 'text'],
        sold_int_90: ['Zelf verkocht (90 dagen)', 'text'],
        stt_i: ['Statijd (intern)', 'text'],
        stock_m: ['Marktvoorraad', 'text'],
        sold_m: ['Verkocht in de markt', 'text'],
        stt_m_sold: ['Statijd markt (verkocht)', 'text'],
        stt_m_stock: ['Statijd markt (voorraad)', 'text'],
        etr_dyn: ['ETR-dynamiek', 'arrow'],
        itr_dyn: ['ITR-dynamiek', 'arrow'],
        advies: ['Advies', 'text']},

        'Waarde': {catalogusprijs: ['catalogusprijs','amount'],
        'marge_€': ['Marge €', 'amount'],
        value: ['Waarde', 'amount'],
        bod: ['Bod', 'amount']},

        'Veiling': {deadline: ['deadline', 'text'],
        auction_label: ['veiling', 'text'],
        window_url: ['window link', 'link'],
        url: ['veiling', 'link'],
        url_gaspedaal: ['gaspedaal', 'link']}

        },

        details: {},
        inventory: [],
        chartData: {chartdata: []},
        viewsData: {chartdata: []},

        chartOptions: {
          chart: {
          },curveType: 'function'
        },

        viewsChartOptions: {
          chart: {
          },curveType: 'function',
          series: {
            0: {targetAxisIndex: 0},
            1: {targetAxisIndex: 1}
          },
          vAxes:{
            0: {title: 'Views per dag'},
            1: {title: 'Clicks per dag'}
          }

        },

        scoreOptions: {
          legend: { position: 'none' },
          bars: 'vertical',
          hAxes: {
            0: {baseline: 5},
          },

          bar: { groupWidth: "90%" }
        }


      }),


    computed: {
      isLot () {
        return this.$route.params.type=='lots'
      },
      viewsChartData () {
        let r = []
        this.cardata.views[0].chartdata.forEach(function(item) {
           r.push([item[0].toString(), item[1], item[2]]) }
          )
        return r
      },
      auctions () {
        return this.cardata.auctions || []
      },

      history () {
        return this.cardata.hist_sales || []
      },

      stock () {
        return this.cardata.stock || []
      },

      transmission () {
        return this.cardata.car.gear || ''
      },

      year () {
        return this.cardata.car.build || ''
      },

      fuel () {
        return this.cardata.car.fuel || ''
      },

      historyFiltered () {
        if (!this.histYeargearOnly) {
          return this.history.filter(l => l.transmission==this.transmission & l.year==this.year);
        } else {
          return this.history || []
        }
      },
      inventoryFiltered () {
        if (!this.invYeargearOnly) {
          return this.stock.filter(l => l.transmission==this.transmission & l.year==this.year);
        } else {
          return this.stock || []
        }
      },
      weights () {
        return this.$store.state.weights
      },
      pageTitle() {
        let fuel = ''
        let gear = ''

        switch (this.fuel) {
          case 'B':
          fuel = "benzine";
          break;
          case 'D':
          fuel = "diesel";
          break;
          case 'H':
          fuel = "hybride";
          break;
          case 'E':
          fuel = "electrisch";
          break;
          default:
          fuel='onbekend'
        }

        switch (this.transmission) {
          case 'H':
          gear = "handgeschakeld";
          break;
          case 'A':
          gear = "automaat";
          break;
          default:
          gear='onbekend'
        }


        if (this.$route.params.type == 'lots') {
          return `Veiling: ${this.cardata.car.make || ''} ${this.cardata.car.model || ''} (${fuel}, ${gear}, ${this.year})`
        } else {
          return `Details: ${this.cardata.car.make || ''} ${this.cardata.car.model || ''} (${fuel}, ${gear}, ${this.year})`
        }


      },

      scoreData() {
        let data = []

        if (!(Object.keys(this.cardata.car).length === 0)) {

          data.push(['Indicator', 'Score', {'role': 'tooltip'}, {'role': 'style'}])
          this.scoreColumns.forEach(element => {
            data.push([element.friendly, parseFloat(parseFloat(this.cardata.car[element.name]).toFixed(1)), `Score: ${parseFloat(this.cardata.car[element.name]).toFixed(1)}. (${10*(10-parseFloat(this.cardata.car[element.name])).toFixed(1)}% scoort hoger)`, this.score2color(this.cardata.car[element.name])])
          });

        }
        return data


      }

    },
    mounted() {
      this.loadData()
    },

    methods: {
      score2color(score) {
        score = 10*score
        var r, g, b = 0;
        if(score < 50) {
          r = 255;
          b = 46;
          g = Math.round(3.1 * score);
        }
        else {
          g = 255;
          b = 46;
          r = Math.round(510 - 5.10 * score);
        }
        var h = r * 0x10000 + g * 0x100 + b * 0x1;
        return '#' + ('000000' + h.toString(16)).slice(-6);
      },

      openLink(link) {
        let auctionWindow = window.open(link, '_blank')
        // Taxatiebeheer.nl has some strange routing and requires a refresh in order for the correct page to show.
        if(link.includes('taxatiebeheer.nl')){
          setTimeout(function(){
            auctionWindow.location.replace(link)
          }, 1000)
        }
        auctionWindow.focus()
      },


      goBack() {
        this.$router.go(-1)
      },

      loadData() {
        this.loading = true
        let pl = {weights: this.weights.weights, page: 0, search: '', filter: {}, sortBy: ['score'], sortDesc: [false]}

        this.$http
        .post(`/api/data/details/${this.$route.params.type}/${this.$route.params.id}`, pl)
        .then(response => {
          this.cardata = response.data.data
          this.loading = false;
        })
        .catch(() => {
          this.loading = false});

      }
    }

  }
</script>
