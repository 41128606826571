var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"app"}},[_c('loader',{ref:"loader"}),(_vm.loggedIn)?_c('v-navigation-drawer',{attrs:{"clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""}},[_c('v-list',_vm._l((_vm.itemsFiltered),function(ref,i){
var icon = ref[0];
var text = ref[1];
var to = ref[2];
return _c('v-list-item',{key:i,attrs:{"to":to,"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)],1)}),1)],1):_vm._e(),(_vm.loggedIn)?_c('v-app-bar',{attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","color":"white"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Vaartlandlogo","contain":"","src":require('./assets/logo.png'),"transition":"scale-transition","width":"120"}})],1),_c('v-spacer'),_c('v-toolbar-title',[_vm._v("Inkoopportaal")]),_c('v-spacer'),_c('v-toolbar-title',{staticClass:"caption"},[_vm._v("Gebruikte weging: "+_vm._s(_vm.usedWeights))]),[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-scale-balance ")])],1)]}}],null,false,978947446)},[_c('v-list',_vm._l((_vm.weightsMenu.items),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.handleWeightsMenuClick(index)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)],(_vm.user)?_c('v-menu',{attrs:{"bottom":"","min-width":"200px","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-large":""}},on),[_c('v-avatar',{attrs:{"color":"brown","size":"36"}},[_c('span',{staticClass:"white--text text-h6"},[_vm._v(_vm._s(_vm.user.initials))])])],1)]}}],null,false,1613465817)},[_c('v-card',[_c('v-list-item-content',{staticClass:"justify-center"},[_c('div',{staticClass:"mx-auto text-center"},[_c('v-avatar',{attrs:{"color":"brown"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.user.initials))])]),_c('h3',[_vm._v(_vm._s(_vm.user.fullname))]),_c('p',{staticClass:"text-caption mt-1"},[_vm._v(" "+_vm._s(_vm.user.email)+" ")]),_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{attrs:{"depressed":"","rounded":"","text":""},on:{"click":function($event){_vm.changePwdDialog=true}}},[_vm._v(" Wachtwoord wijzigen ")]),_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{attrs:{"depressed":"","rounded":"","text":""},on:{"click":_vm.logout}},[_vm._v(" Uitloggen ")])],1)])],1)],1):_vm._e()],2):_vm._e(),_c('v-container',[_c('v-main',[_c('router-view')],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.changePwdDialog),callback:function ($$v) {_vm.changePwdDialog=$$v},expression:"changePwdDialog"}},[_c('v-container',[_c('change-password',{on:{"close":function($event){_vm.changePwdDialog=false}}})],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"100%"},model:{value:(_vm.weightsDialog),callback:function ($$v) {_vm.weightsDialog=$$v},expression:"weightsDialog"}},[_c('weights',{on:{"close":function($event){_vm.weightsDialog=false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }