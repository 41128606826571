export const fuelTypes = [
    {value: 'B', label: 'benzine'},
    {value: 'D', label: 'diesel'},
    {value: 'H', label: 'hybride'},
    {value: 'E', label: 'electrisch'},
]

export const transmissionTypes = [
    {value: 'H', label: 'handgeschakeld'},
    {value: 'A', label: 'automaat'},
]

export const bodyTypes = [
    {value: ['CA', 'CABRIOLET'], label: 'CABRIOLET'},
    {value: ['SP', 'CO', 'COUPE'], label: 'COUPE'},
    {value: ['SM', 'HA', 'HATCHBACK'], label: 'HATCHBACK'},
    {value: ['PE', 'VA', 'PERSONENBUS'], label: 'PERSONENBUS'},
    {value: ['LI', 'SE', 'SEDAN'], label: 'SEDAN'},
    {value: ['ES', 'ST', 'STATIONWAGEN'], label: 'STATIONWAGEN'},
    {value: ['OF', 'SU', 'SUV'], label: 'SUV'},
    {value: ['OT', 'ANDERS'], label: 'ANDERS'},
]