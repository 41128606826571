<template>
  <v-card>
    <loader ref="loader"/>
    <v-card-title>
      <span class="text-h5">Verander wachtwoord</span>
    </v-card-title>

    <v-card-text>
      <v-text-field
      v-model="cred.oldpassword"
      label="Huidig wachtwoord"
      type="password"
      ></v-text-field>
      <v-text-field
      v-model="cred.password"
      label="Nieuw wachtwoord"
      type="password"
      ></v-text-field>
      <v-text-field
      v-model="cred.password2"
      type="password"
      label="Nieuw wachtwoord (opnieuw)"
      ></v-text-field>
      <div style="color:#c21818;">{{ passwordErrorMsg }}</div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="closeDialog" text>Sluit venster</v-btn>
      <v-btn @click="changePwd" text>Wachtwoord wijzigen</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import loader from '@/components/helpers/Loader.vue'
  import httpCalls from '@/mixins/httpCalls'

  export default {
    name: 'changePassword',

    components: {
      loader
    },

    mixins: [httpCalls],

    data: () => ({
      cred: {oldpassword:null, password: null, password2: null},
      passwordErrorMsg: ''
    }),

  methods: {
    closeDialog () { 
      this.$emit('close')
    },
    changePwd () {
      this.passwordErrorMsg = ''
      if (this.cred.password.length < 5) {
        this.passwordErrorMsg='Wachtwoord moet minimaal vijf karakters bevatten'
        return false
      }
      if (this.cred.password != this.cred.password2) {
        this.passwordErrorMsg='Wachtwoorden zijn niet identiek'
        return false
      }
      this.cred.username=this.$store.state.user.username

      this.$http({url: '/api/auth/changepassword', method: 'POST', data: this.cred })
      .then(() => {this.cred = {oldpassword:null, password: null, password2: null}
                  this.$emit('close')})
      .catch(err => this.passwordErrorMsg = err.response.data.message)
    }
  }
};
</script>
