import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import list from '../components/List.vue'
import dashboard from '../components/Dashboard.vue'
import settings from '../components/Settings.vue'
import details from '../components/Details.vue'
import login from '../components/Auth/Login.vue'
import resetPassword from '../components/Auth/ResetPassword.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: dashboard,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/resetpwd',
        name: 'Reset password',
        component: resetPassword
    },
    {
        path: '/login',
        name: 'Login',
        component: login
    },
    {
        path: '/list/:type',
        name: 'List',
        component: list,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: settings,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/details/:type/:id',
        name: 'Details',
        component: details,
        meta: {
            requiresAuth: true
        }
    }

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

export default router
