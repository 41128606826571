<template>
  <v-row align="center" justify="center" 
  style="height:80vh" dense>
  <v-col cols="12" lg="6" md="6" class="fill-height d-flex flex-column justify-center align-center">
    <v-img
    alt="Vaartlandlogo"
    class="shrink mb-5"
    contain
    :src="require('../../assets/logo.png')"
    transition="scale-transition"
    width="120"
    mb-12
    />
    <v-card v-if="state==0" width="35vh">
      <v-card-title>Onbekende link</v-card-title>
      <v-card-text>
        De link die u gebruikt om uw wachtwoord te wijzigen kan niet worden gecontroleerd.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="toLogin">Naar inlogpagina</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="state==1" width="35vh">
      <v-card-title>Wachtwoord opnieuw instellen</v-card-title>
      <v-card-text>
        <v-text-field
        v-model="cred.password"
        label="Wachtwoord"
        type="password"
        ></v-text-field>
        <v-text-field
        v-model="cred.password2"
        type="password"
        label="Wachtwoord (opnieuw)"
        ></v-text-field>
        <div style="color:#c21818;">{{ passwordErrorMsg }}</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="changePwd" text>Wachtwoord wijzigen</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="state==2" width="35vh">
      <v-card-title>Wachtwoord opnieuw ingesteld.</v-card-title>
      <v-card-text>
        Uw wachtwoord is opnieuw ingesteld.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="toLogin">Naar inlogpagina</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="state==3" width="35vh">
      <v-card-title>Ongeldig token</v-card-title>
      <v-card-text>
        U kunt u wachtwoord niet herstellen omdat de link die u heeft aangeklikt ongeldig is, of omdat er iets fout is gegaan ({{ tokenErrorMsg }})
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="toLogin">Naar inlogpagina</v-btn>
      </v-card-actions>
    </v-card>

  </v-col>
</v-row>
</template>

<script>
  export default {
    name: 'resetPassword',

    data: () => ({
      state: 0,
      cred: {password: null, password2: null},
      passwordErrorMsg: '',
      tokenErrorMsg: ''  
    }),

    mounted () {
      this.$http({url: '/auth/checkresettoken', data: {'resetToken': this.$route.query.token}, method: 'POST' })
      .then(() => this.state=1)
      .catch(err => {this.tokenErrorMsg = err.response.data.message
        this.state=3
      })

    },
    methods: {
      changePwd () {
        if (this.cred.password.length < 5) {
          this.passwordErrorMsg='Wachtwoord moet minimaal vijf karakters bevatten'
          return false
        }
        if (this.cred.password != this.cred.password2) {
          this.passwordErrorMsg='Wachtwoorden zijn niet identiek'
          return false
        }
        this.cred.resettoken = this.$route.query.token

        this.$http({url: '/api/auth/changepassword', method: 'POST', data: this.cred })
        .then(() => this.state=2)
        .catch(err => {
          this.passwordErrorMsg = err.response.data.msg})
      },
      toLogin () {
        this.$router.push('/login')
      }
    }

  }
</script>
