<template>
  <v-card>
    <loader ref="loader"/>
    <template v-if="deleteDialog === false">
      <v-card-title>
        <span class="text-h5 mt-3 pt-3 pb-5 mb-5">Zoekopdracht: {{ title }}</span>
      </v-card-title>

      <v-card-text>

        <v-row mt="12" pt="12" v-if="selectedSavedSearchFilter === null">
          <v-text-field
              v-model="filterName"
              label="Naam"
              autofocus
          >
          </v-text-field>
        </v-row>

        <v-row mt="12" pt="12" v-if="selectedSavedSearchFilter !== null">
          <p>Wil je "{{ selectedSavedSearchFilter.filtername }}" overschrijven, of een nieuw filter aanmaken met deze
            criteria?</p>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="saveNew" v-if="selectedSavedSearchFilter === null && filterName && filterName.length > 0" color="success">Opslaan</v-btn>
        <div v-if="selectedSavedSearchFilter !== null">
          <v-btn @click="update" text>Update</v-btn>
          <v-btn @click="$emit('selectedFilterChanged', {})" text>Nieuw filter</v-btn>
        </div>
        <v-btn @click="closeDialog" text>Sluit venster</v-btn>
      </v-card-actions>
    </template>

    <template v-if="deleteDialog === true">
      <v-card-title>
        <span class="text-h5 mt-3 pt-3 pb-5 mb-5">Zoekopdracht: {{ title }} verwijderen</span>
      </v-card-title>
      <v-card-text>
        <v-row mt="12" pt="12">
          <p>Weet je zeker dat je de zoekopdracht "{{ deleteItem ? deleteItem.filtername : 'NEE' }}" definitief wilt
            verwijderen?</p>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="deleteFilter" color="error" text>Verwijderen</v-btn>
        <v-btn @click="closeDialog" text>Niet verwijderen</v-btn>
      </v-card-actions>
    </template>
  </v-card>

</template>


<script>
import loader from '@/components/helpers/Loader.vue'
import httpCalls from '@/mixins/httpCalls'
import axios from "axios";

export default {
  name: 'searchFilterDialog',

  components: {
    loader
  },

  mixins: [httpCalls],

  data: () => ({
    filterName: null,
    responseData: null,
  }),

  props: {
    searchFilters: {},
    selectedSavedSearchFilter: null,
    deleteDialog: {
      type: Boolean,
      default: false,
    },
    deleteItem: {}
  },
  methods: {
    closeDialog() {
      this.reset()
      this.$emit('close')
    },
    async saveNew() {
      this.$refs.loader.startSpinner()
      this.$http({
        url: `/api/user/saved-search-filters`,
        method: 'POST',
        data: {
          filterName: this.filterName,
          searchFilter: this.searchFilters
        }
      }).then(async response => {
        let r = JSON.parse(JSON.stringify(response))
        if (r.data) {
          let response = await this.$store.dispatch('getSavedSearchFilters')
          if (response) {
            this.$emit('selectedFilterChanged', r.data)
            this.closeDialog()
          }
        }
        this.$refs.loader.stopSpinnerSilent()
      }).catch(err => {
        this.$refs.loader.stopSpinnerBad(err)
      })
    },
    async update() {
      this.$refs.loader.startSpinner()
      this.$http({
        url: `/api/user/saved-search-filters`,
        method: 'PUT',
        data: {
          id: this.selectedSavedSearchFilter.id,
          searchFilter: this.searchFilters
        }
      }).then(async response => {
        let r = JSON.parse(JSON.stringify(response))
        if (r.data) {
          let response = await this.$store.dispatch('getSavedSearchFilters')
          if (response) {
            this.closeDialog()
          }
        }
        this.$refs.loader.stopSpinnerSilent()
      }).catch(err => {
        this.$refs.loader.stopSpinnerBad(err)
      })
    },
    reset() {
      this.filterName = null
      this.responseData = {}
    },
    async deleteFilter() {
      await axios({url: `/api/user/saved-search-filters/${this.deleteItem.id}`, method: 'DELETE'})
      if (this.selectedSavedSearchFilter && this.selectedSavedSearchFilter.id === this.deleteItem.id) {
        this.$emit('selectedFilterChanged', null)
      }
      let response = await this.$store.dispatch('getSavedSearchFilters')
      if (response) {
        this.closeDialog()
      }
    },
  },
  computed: {
    title() {
      return this.selectedSavedSearchFilter ? this.selectedSavedSearchFilter.filtername : 'Nieuw'
    },
  }
};
</script>
