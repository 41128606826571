<template>
  <v-container>
    <loader ref="loader"/>
    <v-row>
      <div class="text-h4 mt-5 mb-5 pl-3">Instellingen</div>
    </v-row>
    <v-divider class="mb-10"></v-divider>
    <v-row>
      <v-tabs v-model="tab">
        <v-tab>Instellingen</v-tab>
        <v-tab>Gebruikers</v-tab>
        <v-tab>Veilinggegevens</v-tab>
      </v-tabs>
    </v-row>

    <div v-if="tab==0">
      <v-row>
        <div class="text-subtitle-3 mt-10 pt-5 mb-5 pl-3">Instellingen voor de queries voor interne gegevens</div>
      </v-row>
      <v-divider class="mb-10"></v-divider>
      <v-row>
        <v-col
        cols="12"
        md="6"
        >
        <v-text-field
        label="Lengte interne geschiedenis"
        v-model="settings.history_days"
        type="number">
      </v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col
    cols="12"
    md="6"
    >
    <v-text-field
    label="Minimale aanschafwaarde"
    v-model="settings.min_purchase_price"
    type="number">
  </v-text-field>
</v-col>
</v-row>
<v-row>
  <v-col
  cols="12"
  md="6"
  >
  <v-text-field
  label="Maximale statijd"
  v-model="settings.max_stt"
  type="number">
</v-text-field>
</v-col>
</v-row>
<v-row>
  <v-col
  cols="12"
  md="6"
  >
  <v-text-field
  label="Japie bearer"
  v-model="settings.japie_bearer"
  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
  :type="showPassword ? 'text' : 'password'"
  @click:append="showPassword = !showPassword"
  >
</v-text-field>
</v-col>
</v-row>
<v-row>
  <v-col
  cols="12"
  md="6"
  >
  <v-btn @click="saveSettings"
  >Opslaan</v-btn>
</v-col>
</v-row>

</div>

<div v-if="tab==1">
  <v-row>
    <div class="text-subtitle-3 mt-10 pt-5 mb-5 pl-3">Gebruikers</div>
  </v-row>
  <v-divider class="mb-10"></v-divider>
  <v-row>
    <v-data-table
    dense
    style="width:100%"
    :headers="userHeaders"
    :items="users"
    item-key="username"
    :loading="loading"
    :sort-by="['username']"
    :sort-desc="[true]"
    :items-per-page="15"
    :search="search"
    loading-text="Een ogenblik geduld alstublieft"
    >
    <template v-slot:top>
      <v-text-field
      v-model="search"
      label="Zoeken"
      class="mx-4"
      ></v-text-field>
    </template>
    <template v-slot:item.authorized="{ item }">
      <v-simple-checkbox
      :ripple="false"
      v-model="item.authorized"
      @click="setUserStatus(item, 'authorized', item.authorized)"
      ></v-simple-checkbox>
    </template>
    <template v-slot:item.admin="{ item }">
      <v-simple-checkbox
      :ripple="false"
      v-model="item.admin"
      @click="setUserStatus(item, 'admin', item.admin)"
      ></v-simple-checkbox>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
      small
      @click="deleteUser(item)"
      >
      mdi-delete
    </v-icon>
  </template>
</v-data-table>
</v-row>

</div>
<div v-if="tab==2">
  <v-row>
    <div class="text-subtitle-3 mt-10 pt-5 mb-5 pl-3">Veiling-metadata</div>
  </v-row>
  <v-divider class="mb-10"></v-divider>
  <v-row>
    <v-data-table
    dense
    style="width:100%"
    :headers="auctionsHeaders"
    :items="auctions"
    item-key="auction_name"
    class="elevation-1"
    :loading="loading"
    :sort-by="['auction_name']"
    :sort-desc="[false]"
    :items-per-page="15"
    loading-text="Een ogenblik geduld alstublieft"
    >
    <template v-slot:item.auction="{ item }">
      <v-simple-checkbox
      :ripple="false"
      v-model="item.auction"
      @click="setAuctionStatus(item, !item.auction)"
      ></v-simple-checkbox>
    </template>
  </v-data-table>

</v-row>
</div>
</v-container>

</template>

<script>

  import loader from '@/components/helpers/Loader.vue'
  import httpCalls from '../mixins/httpCalls'

  export default {
    name: 'settings',

    components: {
      loader
    },

    mixins: [httpCalls],

    data: () => ({tab: 0,
      search: '',
      userHeaders: [
      { text: 'Gebruikersnaam', value: 'username' },
      { text: 'E-mail', value: 'email' },
      { text: 'Volledige naam', value: 'fullname' },
      { text: 'Account geregistreerd', value: 'registered' },
      { text: 'Account geldig', value: 'authorized' },
      { text: 'Admin account?', value: 'admin' },
      { text: '', value: 'actions', sortable: false },
      ],
      auctionsHeaders: [
      { text: 'Veilingnaam', value: 'auction_name' },
      { text: 'Veiling?', value: 'auction' },
      { text: '', value: 'actions', sortable: false },
      ],
      auctions: [],
      settings: {},
      users: [],      
      loading: false,
      showPassword: false,
    }),

    mounted () {
      this.loadUsers()
      this.loadSettings()
      this.loadAuctions()
    },

    methods: {
      loadAuctions() {
        this.genericHTTP ({url: '/api/admin/auctions', 
          method:'GET', 
          data: null, 
          responsePart: 'data', 
          responseAssign:'auctions', 
          silent:true})
      },

      setAuctionStatus (item, status, value) {
        this.genericHTTP ({url: `/api/admin/auction/${item.auction_name}`, 
          method:'PUT', 
          data: {item, status, value}, 
          responsePart: null, 
          responseAssign: null, 
          silent:false})
      },

      loadUsers() {
        this.genericHTTP ({url: '/api/admin/users', 
          method:'GET', 
          data: null, 
          responsePart: 'data', 
          responseAssign:'users', 
          silent:true})
      },

      setUserStatus (item, status, value) {
        let userData = {item, status, value}
        this.genericHTTP ({url: `/api/admin/user/${userData.item.username}`, 
          method:'PUT', 
          data: userData, 
          responsePart: 'data', 
          responseAssign:'users', 
          silent:false})
      },

      deleteUser(item) {
        this.genericHTTP ({url: `/api/admin/user/${item.username}`, 
          method:'DELETE', 
          data: null, 
          responsePart: 'data', 
          responseAssign:'users', 
          silent:false})
      },

      loadSettings () {
        this.genericHTTP ({url: '/api/admin/settings', 
          method:'GET', 
          data: null, 
          responsePart: 'data.settings', 
          responseAssign:'settings', 
          silent:true})
      },

      saveSettings () {
        this.genericHTTP ({url: '/api/admin/settings', 
          method:'POST', 
          data: this.settings, 
          responsePart: null, 
          responseAssign: null, 
          silent:false})
      }      
    }
  }
</script>
