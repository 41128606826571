<template>
  <v-container>
    <loader ref="loader"/>
    <v-row justify="center">
      <v-dialog
          v-model="searchFilterDialog"
          persistent
          max-width="50%"
      >
        <search-filter-dialog
            :searchFilters="searchFilters"
            :selectedSavedSearchFilter="selectedSavedSearchFilter"
            @close="searchFilterDialog=false"
            @selectedFilterChanged="selectedSavedSearchFilterValue = $event"
        />
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog
          :value="deleteSearchFilterDialogItem.id !== undefined"
          persistent
          max-width="50%"
      >
        <search-filter-dialog
            @close="deleteSearchFilterDialogItem={}"
            :deleteItem="deleteSearchFilterDialogItem"
            :selectedSavedSearchFilter="selectedSavedSearchFilter"
            @selectedFilterChanged="selectedSavedSearchFilterValue = $event"
            deleteDialog
        />
      </v-dialog>
    </v-row>
    <v-row>
      <div class="text-h4 mt-5 mb-5 pl-3">{{ title }}</div>
    </v-row>
    <v-row>
      <v-divider class="mb-10"></v-divider>
    </v-row>

    <v-row>
      <v-data-table
          dense
          style="width: 100%"
          :headers="headers"
          :options.sync="options"
          :footer-props="{
        'items-per-page-options': [15, 30, 60]
      }"
          :server-items-length="listdata.count"
          :items="listdata.data"
          item-key="id"
          :loading="loading"
          multi-sort
          loading-text="Een ogenblik geduld alstublieft"
          :item-class="auctionVisited"
      >
        <!-- Tooltips -->
      <template v-for="(h, index) in headers" v-slot:[`header.${h.value}`]="{ headers }">
        <v-tooltip bottom :key="index">
          <template v-slot:activator="{ on }">
            <span v-on="on">{{h.text}}</span>
          </template>
          <span>{{h.description ? h.description : h.text}}</span>
        </v-tooltip>
      </template>
        <!-- Searchfilter -->
        <template v-slot:top>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                  v-model="selectedSavedSearchFilter"
                  class="mr-2"
                  item-text="filtername"
                  item-value="id"
                  :items="savedSearchFilters"
                  label="Bestaande zoekopdracht"
                  clearable
              >
                <template v-slot:item="{item}">
                  <span  class="pr-2">{{ item.filtername }}</span>
                  <v-icon
                      small
                      color="red"
                      class="ml-auto"
                      title="Zoekopdracht verwijderen"
                      @click.stop="showDeleteSearchFilterDialog(item)"
                  >$delete</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                  v-if="type=='lots'"
                  class="mr-2"
                  v-model="auctionValue"
                  item-text="label"
                  item-value="label"
                  :items="auctionFilters"
                  small-chips
                  label="Veilingen"
                  multiple
                  >
                <template v-slot:prepend-item>
                  <v-list-item
                    @click="selectAllAuctionsAction"
                  >
                    <v-button>
                      Bekijk alle veilingen
                    </v-button>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Filters
                    <div>
                      <v-btn  color="primary" max-width="100px" class="float-right" rounded @click.stop="clearFilters">
                        <span class="pr-2">Reset</span>
                        <v-icon
                            small
                            title="Zoekopdracht verwijderen"
                        >$delete</v-icon>
                      </v-btn>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12" md="3" v-if="type=='lots'">
                        <v-text-field
                            class="mr-2"
                            v-model="searchFilters.licensePlate"
                            label="Kenteken"
                            multiple
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-select
                            class="mr-2"
                            v-model="searchFilters.fuel"
                            item-text="label"
                            item-value="value"
                            :items="fuelTypes"
                            small-chips
                            label="Brandstof"
                            multiple
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-select
                            class="mr-2"
                            v-model="searchFilters.transmission"
                            item-text="label"
                            item-value="value"
                            :items="transmissionTypes"
                            small-chips
                            label="Transmissie"
                            multiple
                        ></v-select>
                      </v-col>
                      <v-col cols="12" :md="type==='lots' ? 3 : 6">
                        <v-select
                            class="mr-2"
                            v-model="searchFilters.bodyStyle"
                            item-text="label"
                            item-value="value"
                            :items="bodyTypes"
                            small-chips
                            label="Carroserie"
                            multiple
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="3" sm="6">
                        <v-text-field
                            label="Aangeboden vanaf"
                            type="date"
                            v-model="searchFilters.publishedDate"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" sm="6">
                        <v-slider
                            v-model="searchFilters.build"
                            label="Bouwjaar >="
                            max=2030
                            min=2000
                            step="1"
                            thumb-label
                            thumb-size="30"
                        >
                          <template v-slot:append>
                            <v-text-field
                                v-model.number="searchFilters.build"
                                class="mt-0 pt-0"
                                max=2030
                                min=2000
                                step="1"
                                hide-details
                                single-line
                                type="number"
                                style="width: 60px"
                            ></v-text-field>
                          </template>
                        </v-slider>
                      </v-col>
                      <v-col cols="12" md="6" sm="12">
                        <v-range-slider
                                v-if="type=='lots'"
                                v-model="localSliderMileage"
                                @input="onSliderInput"
                                :min="0"
                                :max="300000"
                                :step="10000"
                                thumb-label
                                thumb-size="60"
                                label="Kilometerstand"
                              >
                                <template v-slot:append>
                                  <div class="d-flex">
                                    <v-text-field
                                      v-model.number="inputLowerMileage"
                                      @input="updateLowerMileage"
                                      class="mt-0 pt-0"
                                      :min="0"
                                      :max="300000"
                                      step="1"
                                      hide-details
                                      single-line
                                      type="number"
                                      style="width: 100px; margin-right: 10px;"
                                      label="Kilometers van"
                                    ></v-text-field>
                                    <v-text-field
                                      v-model.number="inputUpperMileage"
                                      @input="updateUpperMileage"
                                      class="mt-0 pt-0"
                                      :min="0"
                                      :max="300000"
                                      step="1"
                                      hide-details
                                      single-line
                                      type="number"
                                      style="width: 100px"
                                      label="Kilometers tot"
                                    ></v-text-field>
                                  </div>
                                </template>
                              </v-range-slider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-combobox
                            v-model="searchFilters.includeMakeModels"
                            :items="searchFilters.includeMakeModels"
                            clearable
                            hint="Vul hier merken of merk model combinaties in en druk op Tab om deze toe te voegen. Voorbeeld: BMW 3-serie, Audi"
                            label="Merk(en) / modellen in zoekresultaat"
                            deletable-chips
                            multiple
                            persistent-hint
                            small-chips
                        >
                        </v-combobox>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-combobox
                            v-model="searchFilters.excludeMakeModels"
                            :items="searchFilters.excludeMakeModels"
                            clearable
                            hint="Vul hier merken of merk model combinaties in en druk op Tab om deze toe te voegen. Voorbeeld: BMW 3-serie, Audi"
                            label="Merk(en) / modellen uitsluiten van het zoekresultaat"
                            deletable-chips
                            multiple
                            persistent-hint
                            small-chips
                        >
                        </v-combobox>
                      </v-col>
                    </v-row>
                    <v-row v-if="type=='lots'" class="pt-5">
                      <v-col cols="12" md="12" sm="12">
                        Score
                      </v-col>
                    </v-row>
                    <v-row v-if="type=='lots'">
                      <v-col cols="12" md="3" sm="4">
                        <v-slider
                            v-model="searchFilters.score"
                            label="Score >="
                            max=10
                            min=0
                            step="0.10"
                            thumb-label
                            thumb-size="30"
                            hide-details
                        >
                          <template v-slot:append>
                            <v-text-field
                                v-model="searchFilters.score"
                                class="mt-0 pt-0"
                                max=10
                                min=0
                                step="0.10"
                                hide-details
                                single-line
                                type="number"
                                style="width: 41px"
                            ></v-text-field>
                          </template>
                        </v-slider>
                      </v-col>
                      <v-col cols="12" md="3" sm="4">
                        <v-slider
                            v-model="searchFilters.etr"
                            label="ETR >="
                            max=5
                            min=0
                            step="1"
                            thumb-label
                            thumb-size="30"
                            hide-details
                        >
                          <template v-slot:append>
                            <v-text-field
                                v-model.number="searchFilters.etr"
                                class="mt-0 pt-0"
                                max=5
                                min=0
                                step="1"
                                hide-details
                                single-line
                                type="number"
                                style="width: 41px"
                            ></v-text-field>
                          </template>
                        </v-slider>
                      </v-col>
                    </v-row>
                    <v-row v-if="type=='lots'">
                      <v-col cols="12" md="2" sm="4">
                        <v-checkbox
                            label="ETR"
                            v-model="searchFilters.includeEtrScore"
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" md="2" sm="4">
                        <v-checkbox
                            label="Model"
                            v-model="searchFilters.includeModelScore"
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" md="2" sm="4">
                        <v-checkbox
                            label="Motor"
                            v-model="searchFilters.includeMotorScore"
                        >
                        </v-checkbox>
                      </v-col>
                      <v-col cols="12" md="2" sm="4">
                        <v-checkbox
                            label="Gepland onderhoud"
                            v-model="searchFilters.includeMaintenanceScore"
                        >
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-btn @click="openSearchFilterDialog()">
                          Zoekopdracht opslaan
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.has_image="{ item }">
          <v-img
            max-height="100"
            max-width="150"
            :src="item.has_image ? 'https://inkoop-tool.s3.eu-central-1.amazonaws.com/auction-images/' + item.license_plate + '.jpg' : '/wat.jpg'"
        ></v-img>
        </template>
        <template v-slot:item.recently_maintained="{ item }">
          {{ item.recently_maintained ? 'ja' : 'nee' }}
        </template>
        <template v-slot:item.body="{ item }">
          {{ getBodyStyleFriendlyName(item.body) }}
        </template>
        <template v-slot:item.score="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <div v-on="on">{{ item.score.toFixed(1) }}</div>
            </template>
            <table v-if="item.model_score !== undefined">
              <tr>
                <td>Model score: </td>
                <td class="px-2 font-weight-bold">{{ item.model_score.toFixed(1) }}</td>
                <td v-if="item.model_score_reliable === true">
                  <v-icon v-if="item.model_score >= 5" size="medium" :style="{ color: score2color(item.model_score) }">mdi-arrow-up-bold-circle</v-icon>
                  <v-icon v-if="item.model_score < 5" size="medium" :style="{ color: score2color(item.model_score) }">mdi-arrow-down-bold-circle</v-icon>
                </td>
                <td v-if="item.model_score_reliable === false"></td>
                <td v-if="item.model_score_reliable === false">
                  <v-icon color="white" size="medium">mdi-alert-box-outline</v-icon>
                </td>
              </tr>
              <tr>
                <td>Motor score: </td>
                <td class="px-2 font-weight-bold">{{ item.motor_score.toFixed(1) }}</td>
                <td v-if="item.motor_score_reliable === true">
                  <v-icon v-if="item.motor_score >= 5" size="medium" :style="{ color: score2color(item.motor_score) }">mdi-arrow-up-bold-circle</v-icon>
                  <v-icon v-if="item.motor_score < 5" size="medium" :style="{ color: score2color(item.motor_score) }">mdi-arrow-down-bold-circle</v-icon>
                </td>
                <td v-if="item.motor_score_reliable === false"></td>
                <td v-if="item.motor_score_reliable === false">
                  <v-icon color="white" size="medium">mdi-alert-box-outline</v-icon>
                </td>
              </tr>
              <tr>
                <td>Onderhouds score: </td>
                <td class="px-2 font-weight-bold">{{ item.maintenance_score.toFixed(1) }}</td>
                <td v-if="item.maintenance_score_reliable === true">
                  <v-icon v-if="item.maintenance_score >= 5" size="medium" :style="{ color: score2color(item.maintenance_score) }">mdi-arrow-up-bold-circle</v-icon>
                  <v-icon v-if="item.maintenance_score < 5" size="medium" :style="{ color: score2color(item.maintenance_score) }">mdi-arrow-down-bold-circle</v-icon>
                </td>
                <td v-if="item.maintenance_score_reliable === false"></td>
                <td v-if="item.maintenance_score_reliable === false">
                  <v-icon color="white" size="medium">mdi-alert-box-outline</v-icon>
                </td>
              </tr>
              <tr>
                <td>ETR: </td>
                <td class="px-2 font-weight-bold">{{ item.auction_etr }}</td>
                <td>
                  <v-icon v-if="item.etr_scaled>= 5" size="medium" :style="{ color: score2color(item.etr_scaled) }">mdi-arrow-up-bold-circle</v-icon>
                  <v-icon v-if="item.etr_scaled < 5" size="medium" :style="{ color: score2color(item.etr_scaled) }">mdi-arrow-down-bold-circle</v-icon>
                </td>
              </tr>
              <tr>
                <td><v-divider></v-divider></td>
                <td><v-divider></v-divider></td>
                <td><v-divider></v-divider></td>
              </tr>
              <tr>
                <td><v-divider></v-divider></td>
                <td><v-divider></v-divider></td>
                <td><v-divider></v-divider></td>
              </tr>
              <tr>
                <td><v-divider color="white"></v-divider></td>
                <td><v-divider color="white"></v-divider></td>
                <td><v-divider color="white"></v-divider></td>
                <td><v-divider color="white"></v-divider></td>
              </tr>
              <tr>
                <td><v-divider></v-divider></td>
                <td><v-divider></v-divider></td>
                <td><v-divider></v-divider></td>
              </tr>
              <tr>
                <td><v-divider></v-divider></td>
                <td><v-divider></v-divider></td>
                <td><v-divider></v-divider></td>
              </tr>
              <tr>
                <td>Ohak bedrag: </td>
                <td v-if="item.avg_ohak_price_reliable == true" class="px-2 font-weight-bold">&euro;{{ item.avg_ohak_price.toFixed(0) }}</td>
                <td v-if="item.avg_ohak_price_reliable == false" class="px-2"></td>
                <td></td>
                <td><v-icon color="white" v-if="item.avg_ohak_price_reliable === false" size="medium">mdi-alert-box-outline</v-icon></td>
              </tr>
              <tr>
                <td>Claim bedrag: </td>
                <td v-if="item.avg_claim_price_reliable == true" class="px-2 font-weight-bold">&euro;{{ item.avg_claim_price.toFixed(0) }}</td>
                <td v-if="item.avg_claim_price_reliable == false" class="px-2"></td>
                <td></td>
                <td><v-icon color="white" v-if="item.avg_claim_price_reliable === false" size="medium">mdi-alert-box-outline</v-icon></td>
              </tr>
              <tr>
                <td >Claim percentage: </td>
                <td v-if="item.claim_occurrence_reliable == true" class="px-2 font-weight-bold">{{ item.claim_occurrence.toFixed(0) }}%</td>
                <td v-if="item.claim_occurrence_reliable == false" class="px-2"></td>
                <td></td>
                <td><v-icon color="white" v-if="item.claim_occurrence_reliable === false" size="medium">mdi-alert-box-outline</v-icon></td>
              </tr>
              <tr>
                <td>Distributieriem interval: </td>
                <td v-if="item.timing_belt_interval !== 0" class="px-2 font-weight-bold">{{ item.timing_belt_interval }}</td>
                <td></td>
              </tr>
            </table>
          </v-tooltip>
        </template>
        <template v-slot:item.margin_idx="{ item }">
          <v-chip :color="getColor(item.margin_idx)" dark>
            {{ parseInt(item.margin_idx * 100) }}
          </v-chip>
        </template>
        <template v-slot:item.days_idx="{ item }">
          <v-chip :color="getColor(item.days_idx)" dark>
            {{ parseInt(item.days_idx * 100) }}
          </v-chip>
        </template>
        <template v-slot:item.etr="{ item }">
          {{ ((!isNaN(-item.etr)) ? item.etr : '') }}
        </template>
        <template v-slot:item.itr="{ item }">
          {{ ((!isNaN(-item.itr)) ? item.itr : '') }}
        </template>
        <template v-slot:item.auction_etr="{ item }">
          {{ ((!isNaN(-item.auction_etr)) ? item.auction_etr : '') }}
        </template>
        <template v-slot:item.auction_itr="{ item }">
          {{ ((!isNaN(-item.auction_itr)) ? item.auction_itr : '') }}
        </template>
        <template v-slot:item.last_sale="{ item }">
          {{ ((!isNaN(-item.last_sale)) ? item.last_sale : '') }}
        </template>
        <template v-slot:item.etr_dyn_scaled="{ item }">
          <v-icon color="green" v-if="item.etr_dyn_scaled>=6"
          >mdi-arrow-up-bold
          </v-icon
          >
          <v-icon color="red" v-if="item.etr_dyn_scaled<=4"
          >mdi-arrow-down-bold
          </v-icon
          >
          <v-icon v-if="item.etr_dyn_scaled>4 && item.etr_dyn_scaled<6"
          >mdi-arrow-right-bold
          </v-icon
          >
        </template>
        <template v-slot:item.itr_dyn_scaled="{ item }">
          <v-icon color="green" v-if="item.itr_dyn_scaled>=6"
          >mdi-arrow-up-bold
          </v-icon
          >
          <v-icon color="red" v-if="item.itr_dyn_scaled<=4"
          >mdi-arrow-down-bold
          </v-icon
          >
          <v-icon v-if="item.itr_dyn_scaled>4 && item.itr_dyn_scaled<6"
          >mdi-arrow-right-bold
          </v-icon
          >
        </template>
        <template v-slot:item.trend_views_scaled="{ item }">
          <v-icon color="green" v-if="item.trend_views_scaled>=6"
          >mdi-arrow-up-bold
          </v-icon
          >
          <v-icon color="red" v-if="item.trend_views_scaled<=4"
          >mdi-arrow-down-bold
          </v-icon
          >
          <v-icon v-if="item.trend_views_scaled>4 && item.trend_views_scaled<6"
          >mdi-arrow-right-bold
          </v-icon
          >
        </template>
        <template v-slot:item.gtrend_scaled="{ item }">
          <v-icon color="green" v-if="item.gtrend_scaled>=6"
          >mdi-arrow-up-bold
          </v-icon
          >
          <v-icon color="red" v-if="item.gtrend_scaled<=4"
          >mdi-arrow-down-bold
          </v-icon
          >
          <v-icon v-if="item.gtrend_scaled>4 && item.gtrend_scaled<6"
          >mdi-arrow-right-bold
          </v-icon
          >
        </template>
        <template v-slot:item.url="{ item }">
          <a @click.stop @click="goToGaspedaal(item)">Gaspedaal</a><br/>
          <a @click.stop @click="goToAuction(item)">Veiling</a><br/>
          <a @click.stop @click="goToKavel(item)">Kavel</a><br/>
          <a @click.stop @click="gotoWindowUrl(item)">Window</a>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>

import loader from '@/components/helpers/Loader.vue'
import searchFilterDialog from '@/components/SearchFilters.vue'
import httpCalls from '../mixins/httpCalls'
import {fuelTypes, transmissionTypes, bodyTypes} from '@/filterMapping'

export default {
  name: 'list',

  components: {
    loader,
    searchFilterDialog,
  },

  mixins: [httpCalls],
  created() {
    if (this.latestPage.page !== undefined) {
      this.options.page = this.latestPage.page
      this.options.itemsPerPage = this.latestPage.itemsPerPage
      this.options.sortBy = this.latestPage.sortBy
      this.options.sortDesc = this.latestPage.sortDesc
    }
  },
  data: () => ({
    searchFilterDialog: false,
    deleteSearchFilterDialogItem: {},
    loadDataTimer: null,
    selectedSavedSearchFilterValue: null,
    fuelTypes: fuelTypes,
    options: {
      page: 1,
      multiSort: true,
      sortBy: ['score'],
      sortDesc: [true],
      itemsPerPage: 15,
    },
    transmissionTypes: transmissionTypes,
    bodyTypes: bodyTypes,
    showWeights: false,

    mmHeaders: [
      {text: 'Score', value: 'score'},

      {
        text: 'Merk',
        align: 'start',
        filterable: true,
        value: 'make',
      },

      {text: 'Model', value: 'model'},
      {text: 'Brandstof', value: 'fuel'},

      {text: 'Carrosserie', value: 'body'},
      {text: 'Transmissie', value: 'gear'},
      {text: 'Bouwjaar', value: 'build'},


      {text: 'ETR', value: 'etr'},
      {text: 'ITR', value: 'itr'},
      {text: 'ETR-ontwikkeling', value: 'etr_dyn_scaled'},
      {text: 'ITR-ontwikkeling', value: 'itr_dyn_scaled'},

      // {text: 'Views-ontwikkeling', value: 'trend_views_scaled'},
      // {text: 'Google searches', value: 'gtrend_scaled'},

      // {text: 'Verkocht in de markt', value: 'sold_m'},
      // {text: 'Marktomvang', value: 'stock_m'},
      // {text: 'Laatste verkoop (dagen geleden)', value: 'last_sale'}
    ],
    lotHeaders: [
      {text: '', value: 'has_image', sortable: false},
      {text: 'Score', value: 'score'},
      // {text: 'ZO', value: 'user_search_matches', description: 'Zoekopdrachten'},
      // {text: 'Pas onderhoudden', value: 'recently_maintained', description: 'Laatste onderhoudsbeurt heeft plaatsgevonden in de afgelopen 3 maanden'},
      {text: 'Kenteken', value: 'license_plate', width: '100px'},
      {text: 'Km', value: 'kmstand'},

      {
        text: 'Merk',
        align: 'start',
        filterable: true,
        value: 'make',
      },

      {text: 'Model', value: 'model'},
      {text: 'Brandstof', value: 'fuel'},

      {text: 'Carrosserie', value: 'body'},
      {text: 'Transmissie', value: 'gear'},
      {text: 'Bouwjaar', value: 'build'},


      // {text: 'ETR', value: 'auction_etr'},
      {text: 'ITR', value: 'auction_itr'},
      {text: 'Japie waarde', value: 'value'},
      // { text: 'ETR-trend', value: 'etr_dyn_scaled' },
      // { text: 'ITR-trend', value: 'itr_dyn_scaled' },

      // {text: 'Views-trend', value: 'trend_views_scaled'},
      // {text: 'Google searches', value: 'gtrend_scaled'},

      // {text: 'Verkocht in de markt', value: 'sold_m'},
      // {text: 'Marktomvang', value: 'stock_m'},
      // {text: 'Laatste verkoop (dagen geleden)', value: 'last_sale'},

      // {text: 'Deadline', value: 'deadline'},
      // {text: 'Veiling', value: 'auction_name'},
      {text: 'datum', value: 'published_date'},
      {text: 'Link', value: 'url'}
    ],
    listdata: {count: 0, data: []},
    loading: true,
    inputLowerMileage: 0,
    inputUpperMileage: 300000,
    sliderLowerMileage: 0,
    sliderUpperMileage: 300000,
  }),
  computed: {
    selectedSavedSearchFilter: {
      get() {
        let foundIndex = this.savedSearchFilters.findIndex(x => x.id === this.selectedSavedSearchFilterValue)
        return foundIndex !== -1 ? this.savedSearchFilters[foundIndex] : null
      },
      set(newValue) {
        this.selectedSavedSearchFilterValue = newValue
      }
    },
    auctionValue: {
      get() {
        return this.$store.state.auctionValue
      },
      set(newValue) {
        this.$store.commit('auctionValue', newValue)
      }
    },
    urlFilter() {
      return this.$route.query.auction
    },
    type() {
      return this.$route.params.type
    },
    auctionFilters() {
      return this.$store.state.auctionFilters
    },
    headers() {
      if (this.$route.params.type == 'prios') {
        return this.mmHeaders
      } else {
        return this.lotHeaders
      }
    },
    title() {
      if (this.$route.params.type == 'prios') {
        return 'Marktmonitor'
      } else {
        return 'Huidig veilingaanbod'
      }
    },
    settings() {
      return this.$store.state.settings
    },
    weights() {
      return this.$store.state.weights
    },
    latestPage() {
      let page = this.$store.state.latestPage.find(x => x.id === this.$route.path)
      if (page === undefined) {
        return {id: this.$route.path, ...this.getPl()}
      }
      return page
    },
    searchFilters: {
      get() {
        return this.$store.state.searchFilters
      },
    },
    savedSearchFilters: {
      get() {
        return this.$store.state.savedSearchFilters
      },
    },
    localSliderMileage() {
      return [this.sliderLowerMileage, this.sliderUpperMileage];
    },
  },

  watch: {
    $route() {
      this.options.sortBy = ['score']
      this.options.page = 1
    },
    '$store.state.latestPage': {
      handler() {
        this.options.page = this.latestPage.page
        this.options.itemsPerPage = this.latestPage.itemsPerPage
        this.options.sortBy = this.latestPage.sortBy
        this.options.sortDesc = this.latestPage.sortDesc
      },
      deep: true,
    },
    weights: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
    auctionValue: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
    options: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
    type: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
    searchFilters: {
      handler(newValue) {
        this.inputLowerMileage = newValue.mileage[0] || 0;
        this.inputUpperMileage = newValue.mileage[1] || 300000;
        this.syncSliderWithInputs();

        this.$store.commit('searchfilters', newValue)
        this.options.page = 1 // Always reset pagination when changing filters
        this.loadData()
      },
      deep: true,
      immediate: true,
    },
    selectedSavedSearchFilterValue: {
      handler() {
        let foundIndex = this.savedSearchFilters.findIndex(x => x.id === this.selectedSavedSearchFilterValue)
        if (foundIndex !== -1) {
          let searchParams = this.savedSearchFilters[foundIndex]
          this.$store.commit('searchfilters', searchParams.searchfilters)
        }
      },
    }
  },

  mounted() {
    this.loadData()
    this.loadAuctionFilters()
    this.loadSavedSearchFilters()
  },
  methods: {
    goToGaspedaal(auction) {
      this.addVisitedAuction(auction)
      window.open(auction.url_gaspedaal, 'popup','width=1000,height=600')
    },
    goToKavel(auction) {
      this.addVisitedAuction(auction)
      this.openSpecialLink(`/details/lots/${auction.id}`)
    },
    goToAuction(auction) {
      this.addVisitedAuction(auction)
      window.open(auction.url, 'popup','width=1000,height=600')
    },
    gotoAuctions(event, item) {
      event.preventDefault()
      item.urls.forEach((link) => window.open(link, '_blank'));
    },
    gotoWindowUrl(auction) {
      this.addVisitedAuction(auction)
      window.open(auction.url, 'popup','width=1000,height=600')
    },
    getPrioColor(value) {
      if (value > 0.12) return 'green'
      else if (value < 0.9) return 'light-grey'
      else return 'red'
    },
    getColor(value) {
      if (value > 1.1) return 'green'
      else if (value < 0.9) return 'red'
      else return 'light-grey'
    },
    showDetails(value) {
      this.$store.dispatch('setLatestPage', {id: this.$route.path, ...this.getPl()})
      this.$router.push(`/details/${this.$route.params.type}/${value.id}`)
    },
    loadAuctionFilters() {
      this.genericDispatch('getAuctionFilters', null, true)
    },
    loadData() {
      clearTimeout(this.loadDataTimer)
      // Delay the call
      this.loadDataTimer = setTimeout(() => {
        this.loading = true
        this.genericHTTP({
          url: `/api/data/list/${this.$route.params.type}`,
          method: 'POST',
          data: this.getPl(),
          responsePart: 'data.data',
          responseAssign: 'listdata',
          silent: true
        })
        this.loading = false
      }, 1000)
    },
    getPl() {
      return {
        weights: this.weights.weights,
        page: this.options.page,
        filter: this.$route.params.type === 'prios' ? {} : {'auction_label': this.auctionValue},
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc,
        itemsPerPage: this.options.itemsPerPage,
        searchFilters: this.searchFilters
      }
    },
    auctionVisited(auction) {
      let visitedAuctions = localStorage.getItem('visitedAuctions') ? JSON.parse(localStorage.getItem('visitedAuctions')) : []
      if (visitedAuctions.includes(auction.id)) {
        return 'visited'
      }
    },
    addVisitedAuction(auction) {
      let visitedAuctions = localStorage.getItem('visitedAuctions') ? JSON.parse(localStorage.getItem('visitedAuctions')) : []
      localStorage.setItem('visitedAuctions', JSON.stringify([...visitedAuctions, auction.id]))
      // Force data-table to reload
      let itemIndex = this.listdata.data.indexOf(auction)
      let editedItem = this.listdata.data[itemIndex];
      this.listdata.data.splice(itemIndex, 1, editedItem)
    },
    openSpecialLink(url) {
      // If link has not been visited before, open in new window. Otherwise _blank.
      let visitedLinks = localStorage.getItem('visitedLinks') ? JSON.parse(localStorage.getItem('visitedLinks')) : []
      let found = visitedLinks.indexOf(url) !== -1
      localStorage.setItem('visitedLinks', JSON.stringify([...visitedLinks, url]))
      let auctionWindow = null
      if(found === true){
        auctionWindow = window.open(url, '_blank')
      }else{
        auctionWindow = window.open(url, 'popup','width=600,height=600')
      }
      // Taxatiebeheer.nl has some strange routing and requires a refresh in order for the correct page to show.
      if(url.includes('taxatiebeheer.nl')){
        setTimeout(function(){
          auctionWindow.location.replace(url)
        }, 1000);
      }
    },
    getBodyStyleFriendlyName(bodyStyleValue) {
      let foundIndex = this.bodyTypes.findIndex(x => x.value.findIndex(a => a === bodyStyleValue) !== -1)
      if (foundIndex !== -1) {
        return this.bodyTypes[foundIndex].label
      }
      return bodyStyleValue
    },
    loadSavedSearchFilters() {
      this.genericDispatch('getSavedSearchFilters', null, true)
    },
    openSearchFilterDialog() {
      this.searchFilterDialog = true
    },
    showDeleteSearchFilterDialog(item) {
      this.deleteSearchFilterDialogItem = {...item}
    },
    clearFilters() {
      this.selectedSavedSearchFilter = {}
      this.$store.commit('searchfilters', {
        fuel: [],
        licensePlate: '',
        transmission: [],
        bodyStyle: [],
        score: 0,
        itr: 0,
        etr: 0,
        build: 2000,
        mileage: [0, 300000],
        zo: 0,
        recentlyMaintained: false,
        includeEtrScore: true,
        includeModelScore: true,
        includeMotorScore: true,
        includeMaintenanceScore: true,
        publishedDate: "",
        includeMakeModels: [],
        excludeMakeModels: [],
      })
    },
    selectAllAuctionsAction() {
      this.auctionValue = []
    },
    score2color(score) {
        score = 10*score
        var r, g, b = 0;
        if(score < 50) {
          r = 255;
          b = 46;
          g = Math.round(3.1 * score);
        }
        else {
          g = 255;
          b = 46;
          r = Math.round(510 - 5.10 * score);
        }
        var h = r * 0x10000 + g * 0x100 + b * 0x1;
        return '#' + ('000000' + h.toString(16)).slice(-6);
    },
    syncSliderWithInputs() {
      // Round slider values to nearest 10000
      this.sliderLowerMileage = this.roundToNearest(this.inputLowerMileage, 10000);
      this.sliderUpperMileage = this.roundToNearest(this.inputUpperMileage, 10000);
    },
    roundToNearest(value, step) {
      return Math.round(value / step) * step;
    },
    onSliderInput(value) {
      // Update only the slider values and then sync with inputs
      this.sliderLowerMileage = value[0];
      this.sliderUpperMileage = value[1];
      this.inputLowerMileage = this.sliderLowerMileage;
      this.inputUpperMileage = this.sliderUpperMileage;
      this.commitMileage();
    },
    updateLowerMileage(value) {
      this.inputLowerMileage = value;
      this.syncSliderWithInputs(); // Sync slider position with new input
      this.commitMileage();
    },
    updateUpperMileage(value) {
      this.inputUpperMileage = value;
      this.syncSliderWithInputs(); // Sync slider position with new input
      this.commitMileage();
    },
    commitMileage() {
      // Update Vuex store and trigger data reload
      this.$store.commit('searchfilters', {
        ...this.searchFilters,
        mileage: [this.inputLowerMileage, this.inputUpperMileage],
      });
      this.options.page = 1;
      this.loadData();
    },
  }
}
</script>


<style>
@media (min-width: 1904px) {
  .container {
    max-width: 1850px;
  }
}

.visited {
  background-color: #cbcbcb;
}
</style>
