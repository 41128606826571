<template>
  <v-app id="app">
    <loader ref="loader"/>
    <v-navigation-drawer
      v-if="loggedIn"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
    <v-list>
      <v-list-item
        v-for="([icon, text, to], i) in itemsFiltered"
        :key="i"
        :to="to"
        link
      >
      <v-list-item-icon>
        <v-icon>{{ icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ text }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</v-navigation-drawer>

<v-app-bar
  v-if="loggedIn"
  :clipped-left="$vuetify.breakpoint.lgAndUp"
  app
  color="white"
>
<div class="d-flex align-center">
  <v-img
    alt="Vaartlandlogo"
    class="shrink mr-2"
    contain
    :src="require('./assets/logo.png')"
    transition="scale-transition"
    width="120"
  />

</div>
<v-spacer/>

<v-toolbar-title>Inkoopportaal</v-toolbar-title>
<v-spacer/>
<v-toolbar-title class="caption">Gebruikte weging: {{ usedWeights }}</v-toolbar-title>
    <template>
    <div class="text-center">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              mdi-scale-balance
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in weightsMenu.items"
            :key="index"
            @click="handleWeightsMenuClick(index)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </template>
<v-menu
  v-if="user"
  bottom
  min-width="200px"
  rounded
  offset-y
>
  <template v-slot:activator="{ on }">
    <v-btn
    icon
    x-large
    v-on="on"
    >
    <v-avatar
    color="brown"
    size="36"
    >
    <span class="white--text text-h6">{{ user.initials }}</span>
  </v-avatar>
  </v-btn>
  </template>
  <v-card>
    <v-list-item-content class="justify-center">
      <div class="mx-auto text-center">
        <v-avatar
        color="brown"
        >
        <span class="white--text text-h5">{{ user.initials }}</span>
      </v-avatar>
      <h3>{{ user.fullname }}</h3>
      <p class="text-caption mt-1">
        {{ user.email }}
      </p>
      <v-divider class="my-3"></v-divider>
      <v-btn
      @click="changePwdDialog=true"
      depressed
      rounded
      text
      >
      Wachtwoord wijzigen
    </v-btn>
    <v-divider class="my-3"></v-divider>
    <v-btn
    @click="logout"
    depressed
    rounded
    text
    >
    Uitloggen
  </v-btn>
  </div>
  </v-list-item-content>
  </v-card>
</v-menu>
</v-app-bar>
<v-container>
  <v-main>
    <router-view/>
  </v-main>
</v-container>
<v-row justify="center">
  <v-dialog
  v-model="changePwdDialog"
  persistent
  max-width="600px"
  >
  <v-container>
  <change-password @close="changePwdDialog=false"/>
</v-container>
</v-dialog>
</v-row>
<v-row justify="center">
  <v-dialog
  v-model="weightsDialog"
  persistent
  max-width="100%"
  >
  <weights @close="weightsDialog=false"/>
  </v-dialog>
</v-row>


</v-app>
</template>

<script>
  import loader from '@/components/helpers/Loader.vue'
  import changePassword from '@/components/Auth/ChangePassword.vue'
  import weights from '@/components/Weights.vue'
  import httpCalls from '@/mixins/httpCalls'

  export default {
    name: 'App',

    components: {
      loader,
      changePassword,
      weights
    },

    mixins: [httpCalls],

    data: () => ({
      changePwdDialog: false,
      weightsDialog: false,
      items: [
        ['mdi-car-clock', 'Veilingen', '/', false],
        ['mdi-clipboard-list', 'Kavels', '/list/lots', false],
        ['mdi-chart-line', 'Marktmonitor', '/list/prios', false],
        ['mdi-cog', 'Instellingen', '/settings', true],
      ],
      availableWeightIds: [],
      weightsMenu: {
        defaultItems: [
          {
            title: 'Instellingen',
            click() {this.weightsDialog = true}
          }
        ],
        items: []
      },
    }),
    watch: {
      availableWeightIds: function () {
        this.createWeightsMenu()
      },
      '$store.state.token': function(token) {
        if (token) {
          this.genericDispatch('getWeights', this.$store.state.user.default_weight, true, true).catch(() => {
            this.genericDispatch('getWeights', 'default', true)
          })
          this.loadAvailableWeights()
        }
      }
    },
    mounted: function () {
      if (this.loggedIn) {
        this.genericDispatch('getWeights', this.$store.state.user.default_weight, true, true).catch(() => {
          this.genericDispatch('getWeights', 'default', true)
        })
        this.loadAvailableWeights()
      }
    },
    created: function () {
      var self=this
      this.$http.interceptors.response.use(undefined, function (err) {
        return new Promise(function () {
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            self.$store.dispatch('logout')
            self.$router.push('/login')
          }
          throw err;
        });
      });
    },
    computed: {
    usedWeights () {
        if (this.$store.state.weightsDirty===true) {
          return this.$store.state.weights.id + ' (veranderd)'          
        } else {
          return this.$store.state.weights.id
        }
    },
    itemsFiltered () {
      return this.items.filter((item) => item[3]==this.user.admin || !item[3])
    },
    user () {
      return this.$store.state.user
    },

    loggedIn () {
      return this.$store.getters.isLoggedIn
    },
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
      .then(() => {
        this.$router.push('/login')
      })
    },
    handleWeightsMenuClick(index) {
      this.weightsMenu.items[index].click.call(this)
    },
    loadAvailableWeights () {
      this.genericHTTP ({url: '/api/admin/weights', 
        method:'GET', 
        data: null, 
        responsePart: 'data', 
        responseAssign:'availableWeightIds',
        silent:true})
    },
    createWeightsMenu () {
      this.weightsMenu.items = Object.assign([], this.weightsMenu.defaultItems)
      this.availableWeightIds.forEach(weight => {
        let obj = {
          title: weight.id,
          click() {
            this.genericDispatch('getWeights', weight.id, false)
          }
        }
        this.weightsMenu.items.push(obj)
      });
    }
  },
};
</script>