<template>
  <v-row align="center" justify="center"
  style="height:80vh" dense>
  <v-col cols="12" lg="6" md="6" class="fill-height d-flex flex-column justify-center align-center">
    <v-img
    alt="Vaartlandlogo"
    class="shrink mb-5"
    contain
    :src="require('../../assets/logo.png')"
    transition="scale-transition"
    width="120"
    mb-12
    />
    <v-card v-if="state==0" width="35vh">
      <v-card-title>Login</v-card-title>
      <v-card-text>
        <v-text-field
        v-model="cred.username"
        label="Gebruikersnaam"
        name="username"
        autocomplete="off"
        ></v-text-field>
        <v-text-field
        v-on:keyup.native.enter="login"
        v-model="cred.password"
        type="password"
        label="Wachtwoord"
        name="password"
        ></v-text-field>
        <div style="color:#c21818;">{{ loginErrorMsg }}</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="login" text>Inloggen</v-btn>
      </v-card-actions>
    </v-card>
    <div v-if="state==0" class="mt-3">Nog geen inloggegevens? Registreer <a @click="state=1">hier</a></div>
    <div v-if="state==0" class="mt-3">Wachtwoord vergeten? Klik <a @click="state=3">hier</a></div>
    <v-card v-if="state==1" width="35vh">
      <v-card-title>Registreren</v-card-title>
      <v-card-text>
        <v-text-field
        v-model="reg.username"
        label="Gebruikersnaam"
        ></v-text-field>
        <v-text-field
        v-model="reg.fullname"
        label="Volledige naam"
        ></v-text-field>
        <v-text-field
        type='email'
        v-model="reg.email"
        label="E-mailadres"
        ></v-text-field>
        <v-text-field
        v-model="reg.password"
        type="password"
        label="Wachtwoord"
        ></v-text-field>
        <v-text-field
        v-on:keyup.native.enter="register"
        v-model="reg.password2"
        type="password"
        label="Wachtwoord (nogmaals)"
        ></v-text-field>
        <div style="color:#c21818;">{{ registrationErrorMsg }}</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="state=0">Terug</v-btn>
        <v-btn @click="register" text>Registreren</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="state==2" width="35vh">
      <v-card-title>Registratie gelukt</v-card-title>
      <v-card-text>
        U bent geregistreerd als gebruiker. Als de beheerder u heeft toegevoegd kunt u inloggen.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="state=0">Naar inlogpagina</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="state==3" width="35vh">
      <v-card-title>Wachtwoord vergeten?</v-card-title>
      <v-card-text>
        <p>Vul hieronder uw e-mailadres in. Een link om het wachtwoord opnieuw in te stellen wordt per e-mail verzonden.</p>
        <v-text-field
        v-model="res.email"
        label="E-mailadres"
        v-on:keyup.native.enter="reset"
        ></v-text-field>
        <div style="color:#c21818;">{{ resetErrorMsg }}</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="state=0">Terug</v-btn>
        <v-btn @click="reset" text>Versturen</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="state==4" width="35vh">
      <v-card-title>Password gereset</v-card-title>
      <v-card-text>
        U krijgt binnen enkele ogenblikken een e-mail om uw password opnieuw in te stellen.
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="state=0">Naar inlogpagina</v-btn>
      </v-card-actions>
    </v-card>
  </v-col>
</v-row>
</template>

<script>
  export default {
    name: 'login',

    data: () => ({
      state: 0,
      res: {email: null},
      cred: {username: null, password: null},
      reg: {username: null, email: null, fullname: null, password: null, password2: null},
      loginErrorMsg: '',
      registrationErrorMsg: '',
      resetErrorMsg: ''
    }),
    methods: {
      login () {
        this.loginErrorMsg = ''
        if (!this.cred.username || !this.cred.password) {
          this.loginErrorMsg='Niet alle velden ingevuld'
          return false
        }
        this.$store.dispatch('login', this.cred)
        .then(() => this.$router.push('/'))
        .catch(err => {if (err.response) {this.loginErrorMsg = err.response.data.msg} else this.loginErrorMsg = ''})
      },

      register () {
        this.registrationErrorMsg=''
        if (!this.reg.username || !this.reg.email || !this.reg.fullname || !this.reg.password || !this.reg.password2) {
          this.registrationErrorMsg='Niet alle velden ingevuld'
          return false
        }
        if (!/.+@.+/.test(this.reg.email)) {
          this.registrationErrorMsg='Onjuist e-mailadres'
          return false
        }
        if (this.reg.password.length < 5) {
          this.registrationErrorMsg='Wachtwoord moet minimaal vijf karakters bevatten'
          return false
        }
        if (this.reg.password != this.reg.password2) {
          this.registrationErrorMsg='Wachtwoorden zijn niet identiek'
          return false
        }
        this.$http({url: '/api/auth/register', data: this.reg, method: 'POST' })
        .then(() => this.state=2)
        .catch(err => this.registrationErrorMsg = err.response.data.msg)
      },
     reset () {
        this.resetErrorMsg = ''
        this.$http({url: '/api/auth/forgotpassword', data: this.res, method: 'POST' })
        .then(() => this.state=4)
        .catch(err => this.resetErrorMsg = err.response.data.msg)

      }
    }

  }
</script>
