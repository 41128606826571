<template>
  <div>
    <div class="text-center">
      <v-snackbar
        v-model="msg.showMessage"
        :color="msg.messagecolor"
      >
        {{ msg.message }}
        <v-btn
          text
          @click="show = false"
        >
          Close
        </v-btn>
      </v-snackbar>
    </div>

    <v-overlay :value="overlay">
      <v-col>
        <v-row
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-row>
        <v-row
          align="center"
          justify="center"
        >
          <span><br>{{ message }}</span>
        </v-row>      
      </v-col>
    </v-overlay>
  </div>
</template>

<script>
 export default {
  name: 'loader',
  data: () => ({
    message: "Even geduld",
    msg: {message: '', showMessage: false, messagecolor: ''},
    overlay: false,
  }),
  methods: {
    startSpinner() {
      this.msg.message = ''
      this.msg.messagecolor = ''
      this.msg.showMessage = false
      this.overlay = true
    },
    stopSpinnerSilent() {
      this.msg.message = ''
      this.msg.messagecolor = ''
      this.msg.showMessage = false
      this.overlay = false
    },
    stopSpinnerGood() {
      this.msg.messagecolor = 'success'
      this.msg.message = 'Gelukt!'
      this.msg.showMessage = true
      this.overlay = false
    },
    stopSpinnerBad(err) {
      this.msg.messagecolor = 'error'
      this.msg.message = err.name + ': ' + err.message
      this.msg.showMessage = true
      this.overlay = false
    },
    test() {
      alert('gooo')
    }
  }
}
</script>