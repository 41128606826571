<template>
  <v-container>
    <loader ref="loader"/>
    <v-row>
      <div class="text-h4 mt-5 mb-5 pl-3">Veilingoverzicht </div>
    </v-row>
    <v-row>
      <v-divider class="mb-10"></v-divider>
    </v-row>
    <v-row>
      <div class="text-subtitle-3 mb-5 pl-3">Aankomende veilingen </div>
    </v-row>
    <v-row>
      <v-divider class="mb-10"></v-divider>
    </v-row>
    <v-row>
      <v-data-table
      dense
      @click:row="gotoAuction"
      style="width:100%"
      :headers="auctionHeaders"
      :items="currentmarket.auctions"
      item-key="id"
      class="elevation-1"
      :loading="loading"
      :items-per-page="15"
      :search="search"
      loading-text="Een ogenblik geduld alstublieft"
      >
    </v-data-table>
  </v-row>
  <v-row>
    <v-col cols="12" md="6">
      <v-container>
        <v-row>
          <div class="text-subtitle-3 mt-10 pt-5 mb-5 pl-3">Aanbod op handelsplatforms</div>
        </v-row>
        <v-row>
          <v-divider class="mb-10"></v-divider>
        </v-row>
        <v-row>

          <v-data-table
          dense
          @click:row="gotoAuction"
          style="width:100%"
          :headers="platformHeaders"
          :items="currentmarket.platforms"
          item-key="id"
          :loading="loading"
          :items-per-page="15"
          :search="search"
          loading-text="Een ogenblik geduld alstublieft"
          >
        </v-data-table>

      </v-row>
    </v-container>
  </v-col>
  <v-col cols="12" md="6">
    <v-container>
      <v-row>
        <div class="text-subtitle-3 mt-10 pt-5 mb-5 pl-3">Meest aangeboden</div>
      </v-row>
      <v-row>
        <v-divider class="mb-10"></v-divider>
      </v-row>
      <v-row>
        <v-data-table
        dense
        style="width:100%"
        :headers="mostofferedHeaders"
        :items="currentmarket.most_offered"
        item-key="id"
        class="elevation-1"
        :loading="loading"
        :items-per-page="15"
        :search="search"
        loading-text="Een ogenblik geduld alstublieft"
        >
      </v-data-table>
    </v-row>
  </v-container>
</v-col>
</v-row>
</v-container>
</template>

<script>
  import loader from '@/components/helpers/Loader.vue'
  import httpCalls from '../mixins/httpCalls'

  export default {
    name: 'dashboard',

    components: {
      loader
    },

    mixins: [httpCalls],

  data: () => ({
    auctionHeaders: [{ text: 'Platform', value: 'auction_name' },
    { text: 'Veiling', value: 'label_auction' },
    { text: 'Datum', value: 'deadline' },
    { text: 'Tijd', value: 'auction_time' },
    { text: 'Aanbod', value: 'cars' }],
    mostofferedHeaders: [{ text: 'Merk', value: 'make' },
    { text: 'Model', value: 'model' },
    { text: 'Brandstof', value: 'fuel' },
    { text: 'Aanbod', value: 'cars' }],
    platformHeaders: [{ text: 'Platform', value: 'auction_name' },
    { text: 'Veiling', value: 'label_auction' },
    { text: 'Aanbod', value: 'cars' }],
    loading: false,
    search: '',
    currentmarket : {}    
  }),

  computed: {
    auctionValue: {
     get(){
       return this.$store.state.auctionValue
     },
     set(newValue){
       this.$store.commit('auctionValue', newValue)
     }
    }
   },
  mounted () {
    this.loadCurrentMarket()
  },

  methods: {
      gotoAuction (value) {
        this.auctionValue = [`${value.auction_name} (${value.label_auction})`]
        this.$router.push(`/list/lots`)
      },
      loadCurrentMarket() {
        this.genericHTTP ({url: '/api/data/currentmarket', 
          method:'GET', 
          data: null, 
          responsePart: 'data.data', 
          responseAssign:'currentmarket', 
          silent:true})
      },
    }
}

</script>
