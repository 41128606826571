<template>
    <v-card>
      <loader ref="loader"/>
      <v-row justify="center">
        <v-dialog
        v-model="saveWeightsDialog"
        persistent
        max-width="50%"
        >
        <v-card>
          <v-card-title>
            <span class="text-h5 pb-5 mb-5">Bewaar wegingsfactoren</span>
          </v-card-title>

          <v-card-text>
            <v-row mt="12" pt="12">
              <v-text-field
              v-model="weightId"
              class="mr-2"
              label="Naam"
              ></v-text-field>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="saveWeightsDialog=false" text>Sluit venster</v-btn>
            <v-btn @click="saveWeights" text>Bewaren</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog
      v-model="loadWeightsDialog"
      persistent
      max-width="50%"
      >
      <v-card>
        <v-card-title>
          <span class="text-h5 pb-5 mb-5">Wegingsfactoren inladen</span>
        </v-card-title>
        <v-card-text>
          <v-row mt="12" pt="12">
            <v-select
            class="mr-2"
            v-model="weightId"
            item-text="id"
            item-value="id"
            :items="availableWeightIds"
            label="Naam"
            ></v-select>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="loadWeightsDialog=false" text>Sluit venster</v-btn>
          <v-btn @click="loadWeights" text>Inladen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  <v-card-title>
    <span class="text-h5 mt-3 pt-3 pb-5 mb-5">Wegingsfactoren: {{ title }}</span>
  </v-card-title>
  <v-card-text>
  <v-row mt="12" pt="12">
    <v-col cols="12" md="3" v-for="(group, groupName) in weights.weights" :key="groupName">
      <h3>{{ fuelTypeLabels[groupName] }}</h3>
      <v-col v-for="(value, name) in group" :key="name">
        <v-slider
          @change="valuesChanged"
          v-model="weights.weights[groupName][name]"
          :label="weightLabels[name]"
          :max=2
          :min=0
          :step=0.01
          thumb-label="always"
          thumb-size="30"
        >
        </v-slider>
      </v-col>
    </v-col>
  </v-row>
</v-card-text>
<v-card-actions>
  <v-spacer></v-spacer>
  <v-btn @click="closeDialog" text>Sluit venster</v-btn>
  <v-btn @click="openLoadDialog" text>Laden</v-btn>
  <v-btn @click="openSaveDialog" text>Bewaren</v-btn>
</v-card-actions>
</v-card>

</template>


<script>
  import loader from '@/components/helpers/Loader.vue'
  import httpCalls from '@/mixins/httpCalls'
  import {fuelTypes} from '@/filterMapping'

  export default {
    name: 'weights',

    components: {
      loader
    },

    mixins: [httpCalls],

    data: () => ({
      weightId: '',
      availableWeightIds: [],
      saveWeightsDialog: false,
      loadWeightsDialog: false,
      weightLabels: {'etr_scaled': 'ETR', 
                    'maintenance_score': 'Onderhoud',
                    'motor_score': 'Motor',
                    'model_score': 'Model',
                    'itr_scaled': 'ITR', 
                    'sold_int_365_scaled': 
                    'Verkocht (intern, 365 dagen)', 
                    'sold_int_90_scaled': 'Verkocht (intern, 90 dagen)', 
                    'sold_m_scaled': 'Verkocht (markt, 90 dagen)', 
                    'stt_m_sold_scaled': 'Statijd markt (verkocht)', 
                    'stt_m_stock_scaled': 'Statijd markt (voorraad)', 
                    'stt_i_scaled': 'Statijd (intern)', 
                    'last_sale_scaled': 'Laatste verkoop (dagen)', 
                    'margin_relative_scaled': 'Relatieve marge', 
                    'etr_dyn_scaled': 'ETR-ontwikkeling', 
                    'itr_dyn_scaled': 'ITR-ontwikkeling', 
                    'gtrend_scaled': 'Google searches', 
                    'trend_views_scaled': 'Views', 
                    'trend_clicks_scaled': 'Clicks'}
    }),
    mounted: function () {
      this.loadAvailableWeights()
    },
    computed: {
      title () {
        if (this.$store.state.weightsDirty===true) {
          return this.weights.id + ' (veranderd)'          
        } else {
          return this.weights.id
        }
      },
      fuelTypeLabels() {
        return fuelTypes.reduce((map, fuelType) => {
          map[fuelType.value] = fuelType.label;
          return map;
        }, {});
      },
      weights: {
       get(){
         return this.$store.state.weights
       },
       set(newValue){
         this.$store.commit('weights', newValue)
       }
     }
    },
  methods: {
    valuesChanged () {
      this.$store.commit('weightsDirty', true)
    },
    closeDialog () {
      this.$emit('close')
    },
    openLoadDialog () {
      this.loadAvailableWeights()
      this.loadWeightsDialog=true
    },
    openSaveDialog () {

      // this.weightId = this.$store.weights.id
      this.saveWeightsDialog = true
    },
    loadAvailableWeights () {    
      this.genericHTTP ({url: '/api/admin/weights', 
        method:'GET', 
        data: null, 
        responsePart: 'data', 
        responseAssign:'availableWeightIds', 
        silent:true})
    },
    loadWeights () {
      this.genericDispatch('getWeights', this.weightId, false)

    },
    saveWeights() {
      this.weights.id = this.weightId
      this.genericDispatch('saveWeights', this.weightId, false)
    }
  }
};
</script>
