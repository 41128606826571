export default {
  methods: {
      resolve(path, obj) {
          return path.split('.').reduce(function(prev, curr) {
              return prev ? prev[curr] : null
          }, obj || self)
      },

      genericDispatch (action, data, silent, promise) {
        this.$refs.loader.startSpinner()
        var self = this
        if (promise == true) {
          return this.$store.dispatch(action, data)
        } else {
          this.$store.dispatch(action, data).then(function() {
            if (silent===true) {
              self.$refs.loader.stopSpinnerSilent()            
            } else {
              self.$refs.loader.stopSpinnerGood()
            }
          }).catch(err => {
            self.$refs.loader.stopSpinnerBad(err)
          })
        }
      },

      genericHTTP (options) {
        this.$refs.loader.startSpinner()
        this.$http({url: options.url, method: options.method, data: options.data })
        .then(response => {
          if (!((options.responsePart == null) || (options.responseAssign == null))) {
            let r = JSON.parse(JSON.stringify(response))
            this[options.responseAssign] = this.resolve(options.responsePart, r)
          }
          if (options.silent===true) {
            this.$refs.loader.stopSpinnerSilent()            
          } else {
            this.$refs.loader.stopSpinnerGood()
          }
        })
        .catch(err => {
          this.$refs.loader.stopSpinnerBad(err)
        })
      }
  }
};